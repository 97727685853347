import { useAtomValue, useSetAtom } from 'jotai';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { userDataSelector } from 'redux-core/auth/selectors';
import { getDownloadURL } from 'components/common/BaseOnlyOffice/utils';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { showSuccessToast } from 'components/common/toastNotifications';
import { ClearanceStatusSelect } from 'components/styleguide/ClearanceStatusSelect';
import { TClearanceStatus } from 'services/clear/clearance-status';
import { TExistingContact, TUpdateStatusAndDateRequest, useUpdateStatusAndDateMutation } from 'services/clear/letters';
import { rootTranslation } from 'utils/format';
import { useState, useEffect } from 'react';
import { composeLetterAtom } from '../composeLetterStore';
import { useChangeSelectedLicensor } from '../hooks/useChangeSelectedLicensor';

const t = rootTranslation('composeLetters.letterTemplate');
const OO_URL = process.env.REACT_APP_OO_HOST;

export function ComposeLetterSelectLetterTemplate({
  formBag,
  onlyOfficeEditorReady,
  isLoadingTemplates,
  letterTemplates,
}) {
  const composeLetter = useAtomValue(composeLetterAtom);
  const user = useSelector(userDataSelector);
  const setComposeLetter = useSetAtom(composeLetterAtom);
  const [updateStatusAndDate] = useUpdateStatusAndDateMutation();
  const { loadNextLicensor } = useChangeSelectedLicensor();

const [stayOnScreen, setStayOnScreen] = useState(() => localStorage.getItem('stayOnScreen') === 'true');

useEffect(() => {
  localStorage.setItem('stayOnScreen', stayOnScreen.toString());
}, [stayOnScreen]);

  formBag.watch(({ letterFileType }, { name, type }) => {
    if (type !== 'change') return;

    if (name === 'letterFileType') {
      setComposeLetter((prev) => ({
        ...prev,
        letter: {
          ...prev.letter,
          letterFileType,
        },
      }));
    }
  });

  return (
    <Grid item container flexDirection="column" gap={3}>
      <Grid item>
        <Typography variant="h5">{t('stepTitle')}</Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }} container gap={2}>
        <FormField
          name="templateId"
          label={t('templateId')}
          key={`select-template-${composeLetter.letter.templateId}`}
          render={({ field, muiprops }) => (
            <>
              {isLoadingTemplates && <Skeleton />}
              {!isLoadingTemplates && (
                <Select
                  {...field}
                  {...muiprops}
                  data-testid="select-letter-template"
                  onChange={(event) => {
                    setComposeLetter((prev) => ({
                      ...prev,
                      letter: {
                        ...prev.letter,
                        templateId: Number(event.target.value),
                        draftId: undefined,
                        editorReady: false,
                        ignoreDraft: true,
                      },
                    }));
                    formBag.setValue('templateId', Number(event.target.value));
                  }}
                >
                  <MenuItem key="letter-template-empty" value={0}>
                    {t('emptyDocumentOption')}
                  </MenuItem>
                  {letterTemplates?.map(({ id, name }) => (
                    <MenuItem
                      key={`letter-template-${id}`}
                      data-testid="compose-letter-select-letter-template-option"
                      value={id}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          )}
        />
        <FormField
          name="clearanceStatusId"
          label={t('licensorStatusId')}
          render={({ field }) => (
            <ClearanceStatusSelect
              divisionId={composeLetter.divisionId}
              clearanceStatus={field.value}
              onChange={(_, status: TClearanceStatus) => {
                field.onChange(status.id);
              }}
            />
          )}
        />
        <FormField
          name="letterFileType"
          label={t('letterFileType')}
          render={({ field, muiprops }) => (
            <ToggleButtonGroup {...field} {...muiprops}>
              <ToggleButton value="pdf">PDF</ToggleButton>
              <ToggleButton value="docx">DOCX</ToggleButton>
            </ToggleButtonGroup>
          )}
        />
        <Grid container gap={2}>
          <Grid item xs={4}>
            <FormField
              name="setStatus"
              label={t('setStatus')}
              render={({ field, muiprops }) => <Switch {...field} {...muiprops} defaultChecked={field.value} />}
            />
          </Grid>
          <Grid item xs={4}>
            <FormField
              name="setSendDate"
              label={t('setSendDate')}
              render={({ field, muiprops }) => <Switch {...field} {...muiprops} defaultChecked={field.value} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              data-testid="compose-letter-download-button"
              disabled={!onlyOfficeEditorReady}
              onClick={async () => {
                const { letterFileType, setStatus, setSendDate, clearanceStatusId, templateId } = formBag.getValues();
                const docxUrl = await getDownloadURL('docx');
                await new Promise<void>((res) => {
                  setTimeout(() => {
                    res();
                  }, 300);
                });
                const pdfUrl = await getDownloadURL('pdf');
                const link = document.createElement('a');
                link.href = `${OO_URL}${letterFileType === 'docx' ? docxUrl : pdfUrl}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                const { clearanceBundleLicensorsIds } = composeLetter;

                if (setStatus || setSendDate) {
                  await Promise.all(
                    clearanceBundleLicensorsIds.map(async (clearanceBundleLicensorId) => {
                      await updateStatusAndDate({
                        divisionId: composeLetter.divisionId,
                        letterFormat: letterFileType === 'pdf' ? 'PDF' : 'WORD',
                        setStatus,
                        setSendDate,
                        bundle: false,
                        contact: composeLetter.contact as TExistingContact,
                        clearanceBundleLicensorsIds: [clearanceBundleLicensorId],
                        licensorStatus: clearanceStatusId,
                        extraAttachments: [],
                        letterTemplateId: templateId || undefined,
                        letterDocxUrl: letterFileType === 'pdf' ? docxUrl : undefined,
                        letterUrl: letterFileType === 'pdf' ? pdfUrl : docxUrl,
                        letterType: composeLetter.letterType,
                        email: {
                          attachmentName: `${composeLetter.documentTitle}.${letterFileType}`,
                          from: user.userEmail,
                          subject: '',
                          to: [],
                          cc: [],
                          bcc: [],
                          body: '',
                        },
                      } as TUpdateStatusAndDateRequest).unwrap();
                    }),
                  );
                }
                showSuccessToast('File downloaded successfully');
                if (!stayOnScreen) {
                  loadNextLicensor();
                }
              }}
              startIcon={<DownloadIcon />}
            >
              {t('downloadButton')}
            </Button>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              paddingTop: 1,
              justifyContent: 'start' }}
            >
              <Switch checked={stayOnScreen} onChange={() => setStayOnScreen(!stayOnScreen)} />
              <Typography>{t('stayOnThisScreen')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
