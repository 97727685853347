/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { batch } from 'react-redux';
import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { getLocationParametersSelector, getLocationFiltersSelector } from 'redux-core/router/selectors';
import { fetchFooterInfo } from 'redux-core/overview/actions';
import { showSnackbar } from 'redux-core/global-error/actions';
import i18next from 'i18next';
import { insertMultipleRights } from 'redux-core/rights/services';
import compose from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import findIndex from 'ramda/src/findIndex';
import lensIndex from 'ramda/src/lensIndex';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';
import set from 'ramda/src/set';
import { setProductionTags } from 'redux-core/tracks/actions';
import { getHideInactiveSelector } from 'redux-core/tracks/selectors';
import { updateAssetRights } from './assets/actions';
import {
  createCueContainerCall,
  deleteCueContainerCall,
  getCueContainerCall,
  getCueContainersCall,
  updateCueContainerCall,
  cuesUpdateMove,
  cuesMoveWithin,
  getCueContainerDetailsCall,
  getCueContainersLightCall,
} from './services';
import { getCueContainersSelector, getCueContainersLimitSelector } from './selectors';

export const ASSETS_LIMIT = 10;
export const CUE_CONTAINERS_LIMIT = 4;

const actionsDefinition = {
  CLEAR_CUE_CONTAINERS: identity,
  SET_CC_PAGE_COUNT: identity,
  SET_CUE_CONTAINERS: identity,
  SET_LOADING: identity,
};

export const { clearCueContainers, setLoading, setCueContainers, setCcPageCount } = createActions(actionsDefinition);

export const fetchCueContainers =
  (filters = {}) =>
    async (dispatch, getState) => {
      dispatch(setLoading(true));
      const state = getState();
      const cueContainers = getCueContainersSelector(state);
      const { type, id } = getLocationParametersSelector(state);
      const LIMIT = getCueContainersLimitSelector(state);
      try {
        const response = await getCueContainersCall(type)({ id, ...filters });
        const newCueContainers = !filters.page ? response.data : cueContainers.concat(response.data);
        batch(() => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          dispatch(setCcPageCount(Math.ceil(response.pagination?.totalCount / LIMIT)));
          dispatch(setCueContainers(newCueContainers));
        });
        dispatch(setProductionTags());
        return response;
      } finally {
        dispatch(setLoading(false));
      }
    };

export const fetchCueContainersLight =
  ({ includeUnassigned = true } = {}) =>
    async (_, getState) => {
      const state = getState();
      const { type, id: subprojectId } = getLocationParametersSelector(state);
      const cueContainers = await getCueContainersLightCall(type)({ subprojectId });
      if (includeUnassigned) return cueContainers;
      return reject(prop('unassignedBin'))(cueContainers);
    };

export const refetchCueContainers = () => (dispatch, getState) => {
  const state = getState();
  const cueContainers = getCueContainersSelector(state);
  const LIMIT = getCueContainersLimitSelector(state);
  if (!cueContainers?.length) return;
  const filters = getLocationFiltersSelector(state);
  return dispatch(
    fetchCueContainers({
      filters,
      limit: Math.ceil(cueContainers.length / LIMIT) * LIMIT,
    }),
  );
};

export const deleteCueContainer = (id) => async (dispatch, getState) => {
  const state = getState();
  const { type, divisionId } = getLocationParametersSelector(state);
  await deleteCueContainerCall(type)({ id, divisionId });
  dispatch(refetchCueContainers());
  const isCampaign = type === NORMALIZED_PRODUCTION_TYPES.MKT;
  const message = i18next.t(`productionOverview.tabs.overview.${isCampaign ? 'assets' : 'scenes'}.deletedMessage`);
  await dispatch(showSnackbar({ message }));
};

export const createCueContainer =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ rightsPresetId, requiredRights, territories, ...payload }) =>
    async (dispatch, getState) => {
      const state = getState();
      const { id: productionId, type = payload.type, divisionId } = getLocationParametersSelector(state);

      const isCampaign = type === NORMALIZED_PRODUCTION_TYPES.MKT;

      const cueContainer = await createCueContainerCall(type)({
        productionId,
        divisionId,
        ...payload,
      });

      if (isCampaign) {
        await insertMultipleRights({
          divisionId,
          cueContainerId: cueContainer.id,
          rights: requiredRights,
        });
      }

      dispatch(refetchCueContainers());
      const message = isCampaign
        ? i18next.t('drawers.assets.notifications.create.success')
        : i18next.t('drawers.productionSetup.scenes.notification.save', {
          name: cueContainer.number,
        });
      dispatch(showSnackbar({ message }));
      return cueContainer;
    };

/**
 * Receives an array of cue containers and update the already existing information
 * with the new one
 * @param  {Array} cueContainers
 */
const updateCueContainersInStore =
  (...args) =>
    async (dispatch, getState) => {
      const payloads = args.flat();
      const state = getState();
      const cueContainers = getCueContainersSelector(state);
      let updatedCueContainers = [...cueContainers];
      payloads.forEach((payload) => {
        const index = findIndex(propEq('id', payload.id))(cueContainers);
        if (index >= 0) {
          const lens = lensIndex(index);
          updatedCueContainers = set(lens, payload)(updatedCueContainers);
        }
      });
      dispatch(setCueContainers(updatedCueContainers));
      return updatedCueContainers;
    };

export const updateTrackCueContainerInStore = (trackId, updatedValues) =>
  async (dispatch, getState) => {
    const state = getState();
    const cueContainers = getCueContainersSelector(state);
    const newCueContainers = cueContainers.map((cueContainer) => ({
      ...cueContainer,
      tracks: cueContainer.tracks.map((cueContainerTrack) => {
        if (cueContainerTrack.id === trackId) {
          return {
            ...cueContainerTrack,
            ...updatedValues,
          };
        }
        return cueContainerTrack;
      }),
    }));
    dispatch(setCueContainers(newCueContainers));
  };

/**
 * Refetch all the information for the given cue containers in the store
 */
export const refetchCueContainersById =
  (...args) =>
    async (dispatch, getState) => {
      const ids = args.flat();
      if (!ids.length) return;
      const state = getState();
      const cueContainers = getCueContainersSelector(state);
      if (!cueContainers?.length) return;
      const { divisionId, type, filters } = getLocationParametersSelector(state);
      const hideInactiveCues = getHideInactiveSelector(state);
      const cueContainersToUpdate = await Promise.all(
        ids.map((id) =>
          getCueContainerDetailsCall(type)({
            divisionId,
            id,
            /**
             * if active is undefined, the backend returns all the tracks
             * if active is true, the backend returns the active tracks only
             */
            filters: {
              ...filters,
              active: hideInactiveCues || undefined,
            },
          }),
        ),
      );
      await dispatch(updateCueContainersInStore(cueContainersToUpdate));
      await dispatch(fetchFooterInfo());
    };

export const updateCueContainer =
  (payload, prevValues, overwriteExistingClearancesRights = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const { id: productionId, type = payload.type, divisionId } = getLocationParametersSelector(state);

      const isCampaign = type === NORMALIZED_PRODUCTION_TYPES.MKT;

      const cueContainer = await updateCueContainerCall(type)({
        productionId,
        divisionId,
        ...payload,
      });
      if (isCampaign) {
        await updateAssetRights(payload, prevValues, overwriteExistingClearancesRights);
      }

      dispatch(refetchCueContainersById(payload.id));
      const message = isCampaign
        ? i18next.t('drawers.assets.notifications.update.success')
        : i18next.t('drawers.productionSetup.scenes.notification.edit', {
          name: cueContainer.number,
        });
      dispatch(showSnackbar({ message }));
      return cueContainer;
    };

export const getCueContainer =
  (payload = {}) =>
    async (_, getState) => {
      const state = getState();
      const { divisionId, type = payload.type } = getLocationParametersSelector(state);
      // eslint-disable-next-line no-return-await
      return await getCueContainerCall(type)({ divisionId, ...payload });
    };

export const moveCueContainer = (payload) => async (dispatch, getState) => {
  const state = getState();
  const { id: productionId, type = payload.type, divisionId } = getLocationParametersSelector(state);

  await updateCueContainerCall(type)({
    productionId,
    divisionId,
    ...payload,
  });
  const isCampaign = type === NORMALIZED_PRODUCTION_TYPES.MKT;

  await dispatch(refetchCueContainers());
  const message = i18next.t(`productionOverview.tabs.overview.${isCampaign ? 'assets' : 'scenes'}.movedMessage`);
  dispatch(showSnackbar({ message }));
};

/**
 * Receives track information and merge that information with the already present in store
 * This method is useful for updating fields that doesn't impact any calculations.
 * e.g. adding/removing a bookmarks
 */
export const updateCueContainersByTrackId =
  ({ trackId, ...payload }) =>
    async (dispatch, getState) => {
      const state = getState();
      const cueContainers = getCueContainersSelector(state);
      const updatedCueContainers = cueContainers.map(({ tracks, ...cueContainer }) => {
        const updatedTracks = tracks?.map((track) => {
          if (track.id === trackId) return { ...track, ...payload };
          return track;
        });
        return { tracks: updatedTracks, ...cueContainer };
      });
      dispatch(setCueContainers(updatedCueContainers));
    };

/**
 * Refetchs all the cue containers for a certain track
 */
export const refreshCueContainersByTrackProp =
  (value, key = 'id') =>
    async (dispatch, getState) => {
      const state = getState();
      const cueContainers = getCueContainersSelector(state);
      const cueContainersToUpdate = compose(
        map(prop('id')),
        filter(({ tracks }) => tracks?.some(propEq(key, value))),
      )(cueContainers);
      if (!cueContainersToUpdate.length) return;
      await dispatch(refetchCueContainersById(cueContainersToUpdate));
    };

export const moveCues =
  ({ sameCueContainer = undefined, ...payload }) =>
    async () => {
      if (sameCueContainer) {
        await cuesMoveWithin(payload);
      } else {
        await cuesUpdateMove(payload);
      }
    };
