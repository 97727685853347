import { createSelector } from 'reselect';
import { prop } from 'ramda';

const getAuthRootSelector = (state) => prop('auth', state);

export const userDataSelector = createSelector(getAuthRootSelector, prop('userData'));

export const userEmailSelector = createSelector(userDataSelector, prop('userEmail'));

export const userNameSelector = createSelector(userDataSelector, prop('displayName'));

export const userIdSelector = createSelector(userDataSelector, prop('userId'));

export const interceptedRequestsSelector = createSelector(getAuthRootSelector, prop('interceptedRequests'));

export const isAuthenticatedSelector = createSelector(userDataSelector, (s) => !!prop('bearerToken', s));

export const idleTiemoutSelector = createSelector(userDataSelector, prop('defaultIdleTimeout'));

export const isLockedSelector = createSelector(userDataSelector, prop('locked'));

export const getBearerTokenSelector = createSelector(userDataSelector, prop('bearerToken'));

export const getIsQwireEmployeeSelector = createSelector(userDataSelector, prop('qwireEmployee'));

export const getTenantIdsSelector = createSelector(userDataSelector, prop('tenantIds'));
