/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import { Suspense, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loading, Tabs, zIndexLevels } from 'components/styleguide';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useRootTranslation } from 'utils/hooks';
import flatten from 'ramda/src/flatten';
import is from 'ramda/src/is';
import head from 'ramda/src/head';
import equals from 'ramda/src/equals';
import includes from 'ramda/src/includes';
import path from 'ramda/src/path';
import enhancer from './enhancer';
import TabsLayoutHeader from './TabsLayoutHeader';

const getTabIndex = (tabs, pathname) =>
  /** route can either be a string or an array  */
  tabs.findIndex(({ route }) => (is(Array, route) ? includes(pathname, route) : equals(route, pathname)));
const TabsLayout = ({ tabs, goToLink, pageTitle, headerChildren, disabled, ...props }) => {
  const { state, pathname } = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(getTabIndex(tabs, pathname));
  const t = useRootTranslation();

  useEffect(
    () => {
      const nextTabIndex = getTabIndex(tabs, pathname);
      nextTabIndex !== -1 && setCurrentTabIndex(nextTabIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, tabs.length],
  );

  /** Routes can be a string or an array of strings, so we flatten it */
  const routes = flatten(tabs.filter(({ disabled }) => !disabled).map((t) => t.route));
  if (!routes.includes(pathname)) {
    if (!routes.length) {
      return <Redirect to="/" />;
    }
    return (
      <Redirect
        to={{
          pathname: routes[0],
          state,
        }}
      />
    );
  }

  const handleTabChange = (nextTabIndex) => {
    const { route, onTabSelect } = tabs[nextTabIndex];
    const nextTabRoute = is(Array, route) ? head(route) : route;
    goToLink(nextTabRoute, undefined, { mergeParams: true });
    onTabSelect?.();
  };

  const TabComponent = path([currentTabIndex, 'component'], tabs);

  const tabTitles = tabs.map((tab) => t(tab.title, tab.titleData));

  const handleIsTabDisabled = (index) => tabs[index].disabled || disabled;

  return (
    <Box style={{ zIndex: zIndexLevels.two }}>
      <Grid container>
        {headerChildren && (
          <TabsLayoutHeader pageTitle={pageTitle} tabs={tabs}>
            {headerChildren}
          </TabsLayoutHeader>
        )}
        <Tabs
          onTabChange={handleTabChange}
          currentTab={currentTabIndex}
          tabList={tabTitles}
          isTabDisabled={handleIsTabDisabled}
          aria-label={t('global.tabs.ariaLabel')}
          {...props}
        >
          <Suspense fallback={<Loading />}>{TabComponent}</Suspense>
        </Tabs>
      </Grid>
    </Box>
  );
};

export default enhancer(TabsLayout);
