/* eslint-disable import/no-named-as-default, import/no-named-as-default-member */
import React from 'react';
import PropTypes from 'prop-types';
import enhancer from './enhancer';
import CueSheetVersionDrawer from './CueSheetVersionDrawer';
import CueSheetSubmissionHistoryDrawer from './CueSheetSubmissionHistoryDrawer';
import AddKeyPersonnelDrawer from './AddKeyPersonnelDrawer';
import AddTrackDrawer from './AddTrackDrawer';
import AkaTitlesDrawer from './AkaTitlesDrawer';
import AssetDrawer from './AssetDrawer';
import BudgetDrawer from './BudgetDrawer';
import FkaTitlesDrawer from './FkaTitlesDrawer';
import CreateTrackDrawer from './CreateTrackDrawer';
import ViewTaskDrawer from './TaskDrawer/ViewTaskDrawer';
import CueAdvancedSearchDrawer from './CueAdvancedSearchDrawer';
import EditTaskDrawer from './TaskDrawer/EditTaskDrawer';
import CurrencyDrawer from './CurrencyDrawer';
import InviteUsersDrawer from './InviteUsersDrawer';
import PersonnelDrawer from './PersonnelDrawer/index';
import PositionDrawer from './PositionDrawer/index';
import ProductionCompaniesDrawer from './ProductionCompaniesDrawer';
import ProductionCompanyDrawer from './ProductionCompanyDrawer';
import ProjectDrawer from './ProjectDrawer';
import ReleaseDrawer from './ReleaseDrawer';
import SceneDrawer from './SceneDrawer';
import SeasonDrawer from './SeasonDrawer';
import EditorsDrawer from './EditorsDrawer';
import DistributorsDrawer from './DistributorsDrawer';
import ProducersDrawer from './ProducersDrawer';
import DirectorsDrawer from './DirectorsDrawer';
import ActorsDrawer from './ActorsDrawer';
import BlanketAgreementsDrawer from './BlanketAgreementsDrawer';
import NetworksDrawer from './NetworksDrawer';
import SearchDrawer from './SearchDrawer';
import SearchProductionDrawer from './SearchProductionDrawer';
import TerritoriesDrawer from './TerritoriesDrawer';
import TrackFileDrawer from './FileAttachmentsDrawer/TrackFilesDrawer';
import OverviewFilesDrawer from './FileAttachmentsDrawer/OverviewFilesDrawer';
import DropzoneFilesDrawer from './FileAttachmentsDrawer/DropzoneFilesDrawer';
import ClearanceOptionDrawer from './ClearanceOptionDrawer';
import ClearanceFullListDrawer from './ClearanceFullListDrawer';
import ContactsDrawer from './ContactsDrawer';
import ClearanceAddEdit from './ClearanceAddEdit';
import DisplayCurrenciesDrawer from './DisplayCurrenciesDrawer';
import CompleteAssetDrawer from './CompleteAssetDrawer';
import QwireTracksSync from './QwireTracksSync';
import VersionManagerDuplicate from './VersionManagerDuplicate';
import VersionManagerAddEdit from './VersionManagerAddEdit';
import ScenarioManagerAddEdit from './ScenarioManagerAddEdit';
import GlobalSearchFilter from './GlobalSearchFilter';
import ShareWithUserDrawer from './ShareWithUserDrawer';
import UnassignedBinDrawer from './UnassignedBinDrawer';
import UsageNotesDrawer from './UsageNotesDrawer';
import RightPresetDrawer from './RightPresetDrawer';
import WatchingAssetsDrawer from './WatchingAssetsDrawer';
import LettersExtraAttachmentsDrawer from './LettersExtraAttachmentsDrawer';
import LicensorsAddEdit from './LicensorsAddEdit';
import ChangeRepDrawer from './ChangeRepDrawer';
import ClearanceUsesAndDurations from './ClearanceUsesAndDurationsDrawer';
import ClearanceOtherUseAndDurationDrawer from './ClearanceOtherUseAndDurationDrawer';
import ScenesAdvancedFiltersDrawer from './AdvancedFiltersDrawer/ScenesAdvancedFiltersDrawer';
import SubmitCueSheetDrawer from './SubmitCueSheetDrawer';
import AssetsAdvancedFiltersDrawer from './AdvancedFiltersDrawer/AssetsAdvancedFiltersDrawer';
import TracksAdvancedFiltersDrawer from './AdvancedFiltersDrawer/TracksAdvancedFiltersDrawer';
import LicensingFilter from './LicensingFilter';
import CueSheetDetailsDrawer from './CueSheetDetailsDrawer';
import BundleDrawer from './BundleDrawer';
import PayeesAddEdit from './PayeesAddEdit';
import DRAWERS from './constants';
import ImportCueSheetDrawer from './ImportCueSheetDrawer';
import ChangeCueDetailsDrawer from './ChangeCueDetailsDrawer';
import CueRightOwnersPresetDrawer from './CueRightOwnersPresetDrawer';
import CueSheetExportDrawer from './CueSheetExportDrawer';
import CueSheetSyncDrawer from './CueSheetSyncDrawer';
import ImportSaveAsTemplateDrawer from './ImportSaveAsTemplateDrawer';
import QuickSetupProductionDrawer from './QuickSetupProductionDrawer';
import LettersSetDatesDrawer from './LettersSetDatesDrawer';
import { CueSheetSegmentUpsertDrawer } from './CueSheetSegmentUpsertDrawer';
import { CustomStatusDrawer } from './CustomStatusDrawer';

const drawers = {
  [DRAWERS.LETTERS_EXTRA_ATTACHMENTS]: LettersExtraAttachmentsDrawer,
  [DRAWERS.LETTERS_SET_DATES]: LettersSetDatesDrawer,
  [DRAWERS.PERSONNEL_ADD_EDIT_POSITION]: PositionDrawer,
  [DRAWERS.PERSONNEL_ADD_KEY_PERSONNEL]: AddKeyPersonnelDrawer,
  [DRAWERS.PRODUCTION_ADD_TRACK]: AddTrackDrawer,
  [DRAWERS.PRODUCTION_OVERVIEW_BUDGET]: BudgetDrawer,
  [DRAWERS.PRODUCTION_OVERVIEW_FILE]: OverviewFilesDrawer,
  [DRAWERS.PERSONNEL_LIST_PERSONNEL]: PersonnelDrawer,
  [DRAWERS.PRODUCTION_CREATE_TRACK]: CreateTrackDrawer,
  [DRAWERS.PRODUCTION_SETUP_ASSETS]: AssetDrawer,
  [DRAWERS.PRODUCTION_SETUP_COMPANIES]: ProductionCompaniesDrawer,
  [DRAWERS.PRODUCTION_SETUP_COMPANY]: ProductionCompanyDrawer,
  [DRAWERS.PRODUCTION_SETUP_CURRENCIES]: CurrencyDrawer,
  [DRAWERS.PRODUCTION_SETUP_PROJECT]: ProjectDrawer,
  [DRAWERS.PRODUCTION_SETUP_RELEASE]: ReleaseDrawer,
  [DRAWERS.PRODUCTION_SETUP_SCENES]: SceneDrawer,
  [DRAWERS.PRODUCTION_SETUP_SEASON]: SeasonDrawer,
  [DRAWERS.PRODUCTION_SETUP_AKA_TITLES]: AkaTitlesDrawer,
  [DRAWERS.PRODUCTION_SETUP_FKA_TITLES]: FkaTitlesDrawer,
  [DRAWERS.PRODUCTION_DETAILS_EDITORS]: EditorsDrawer,
  [DRAWERS.PRODUCTION_DETAILS_DISTRIBUTORS]: DistributorsDrawer,
  [DRAWERS.PRODUCTION_DETAILS_DIRECTORS]: DirectorsDrawer,
  [DRAWERS.PRODUCTION_DETAILS_ACTORS]: ActorsDrawer,
  [DRAWERS.PRODUCTION_SETUP_BLANKET_AGREEMENTS]: BlanketAgreementsDrawer,
  [DRAWERS.PRODUCTION_QUICK_SETUP_PRODUCTION]: QuickSetupProductionDrawer,
  [DRAWERS.PRODUCTION_DETAILS_NETWORKS]: NetworksDrawer,
  [DRAWERS.PRODUCTION_DETAILS_PRODUCERS]: ProducersDrawer,
  [DRAWERS.PRODUCTION_DETAILS_CURRENCIES]: DisplayCurrenciesDrawer,
  [DRAWERS.SETTINGS_RIGHTS_PRESET_TERRITORIES]: TerritoriesDrawer,
  [DRAWERS.SETTINGS_CONTACTS]: ContactsDrawer,
  [DRAWERS.CLEARANCE_OPTION]: ClearanceOptionDrawer,
  [DRAWERS.CLEARANCE_ADD_EDIT]: ClearanceAddEdit,
  [DRAWERS.CLEARANCE_FULL_LIST]: ClearanceFullListDrawer,
  [DRAWERS.TRACK_FILE]: TrackFileDrawer,
  [DRAWERS.SEARCH_DRAWER]: SearchDrawer,
  [DRAWERS.SEARCH_PRODUCTION_DRAWER]: SearchProductionDrawer,
  [DRAWERS.CAMPAIGN_COMPLETE_ASSET]: CompleteAssetDrawer,
  [DRAWERS.VERSION_MANAGER_ADD_EDIT]: VersionManagerAddEdit,
  [DRAWERS.VERSION_MANAGER_DUPLICATE]: VersionManagerDuplicate,
  [DRAWERS.TASK_VIEW]: ViewTaskDrawer,
  [DRAWERS.TASK_EDIT]: EditTaskDrawer,
  [DRAWERS.SCENARIO_MANAGER_ADD_EDIT]: ScenarioManagerAddEdit,
  [DRAWERS.GLOBAL_SEARCH_FILTER]: GlobalSearchFilter,
  [DRAWERS.SHARE_WITH_A_USER]: ShareWithUserDrawer,
  [DRAWERS.INVITE_USERS]: InviteUsersDrawer,
  [DRAWERS.QWIRE_TRACKS_SYNC]: QwireTracksSync,
  [DRAWERS.UNASSIGNED_BIN]: UnassignedBinDrawer,
  [DRAWERS.USAGE_NOTES]: UsageNotesDrawer,
  [DRAWERS.RIGHT_PRESET]: RightPresetDrawer,
  [DRAWERS.WATCHING_ASSETS_DRAWER]: WatchingAssetsDrawer,
  [DRAWERS.LICENSORS_ADD_EDIT]: LicensorsAddEdit,
  [DRAWERS.CHANGE_REP_DRAWER]: ChangeRepDrawer,
  [DRAWERS.SEARCH_ASSETS_ADVANCED_DRAWER]: AssetsAdvancedFiltersDrawer,
  [DRAWERS.SEARCH_SCENES_ADVANCED_DRAWER]: ScenesAdvancedFiltersDrawer,
  [DRAWERS.SEARCH_TRACKS_ADVANCED_DRAWER]: TracksAdvancedFiltersDrawer,
  [DRAWERS.LICENSING_FILTER]: LicensingFilter,
  [DRAWERS.CLEARANCES_USES_AND_DURATIONS]: ClearanceUsesAndDurations,
  [DRAWERS.CLEARANCE_OTHER_USE_AND_DURATION]: ClearanceOtherUseAndDurationDrawer,
  [DRAWERS.DROPZONE_FILES_DRAWER]: DropzoneFilesDrawer,
  [DRAWERS.CUE_SHEET_DETAILS_DRAWER]: CueSheetDetailsDrawer,
  [DRAWERS.CUE_ADVANCED_SEARCH_DRAWER]: CueAdvancedSearchDrawer,
  [DRAWERS.CUE_SHEET_VERSION]: CueSheetVersionDrawer,
  [DRAWERS.CUE_SHEET_SUBMISSION_HISTORY]: CueSheetSubmissionHistoryDrawer,
  [DRAWERS.IMPORT_CUE_SHEET]: ImportCueSheetDrawer,
  [DRAWERS.SUBMIT_CUE_SHEET]: SubmitCueSheetDrawer,
  [DRAWERS.BUNDLE_DRAWER]: BundleDrawer,
  [DRAWERS.PAYEES_ADD_EDIT]: PayeesAddEdit,
  [DRAWERS.CUE_RIGHT_OWNERS_PRESET]: CueRightOwnersPresetDrawer,
  [DRAWERS.CHANGE_CUE_DETAILS]: ChangeCueDetailsDrawer,
  [DRAWERS.CUE_SHEET_EXPORT]: CueSheetExportDrawer,
  [DRAWERS.CUE_SHEET_SYNC]: CueSheetSyncDrawer,
  [DRAWERS.IMPORT_SAVE_AS_TEMPLATE]: ImportSaveAsTemplateDrawer,
  [DRAWERS.CUE_SHEET_SEGMENT_UPSERT]: CueSheetSegmentUpsertDrawer,
  [DRAWERS.CUSTOM_STATUS]: CustomStatusDrawer,
};

const Drawers = ({ closeDrawerWithPrompt, open, payload = {} }) =>
  Object.keys(drawers).map((drawer) => {
    const Component = drawers[drawer];
    return (
      <Component key={drawer} name={drawer} open={open === drawer} onClose={closeDrawerWithPrompt} payload={payload} />
    );
  });

Drawers.propTypes = {
  closeDrawerWithPrompt: PropTypes.func.isRequired,
  open: PropTypes.string,
  payload: PropTypes.any,
};

export default enhancer(Drawers);
