import { ComponentProps, ReactElement, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useController,
  UseFormStateReturn,
} from 'react-hook-form';
import { FormHelperText, InputLabel, styled } from '@mui/material';
import Fade from '@mui/material/Fade';
import { FormControl } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import { useRootTranslation } from 'utils/useRootTranslation';
import { Box } from '@mui/system';

type RenderProps = {
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

export type FormFieldMUIProps = {
  error?: boolean;
  id: string;
};

export type FormFieldForwardedProps = RenderProps['field'] & FormFieldMUIProps;

type FormFieldRenderProps = RenderProps & {
  muiprops: FormFieldMUIProps;
};

type FormFieldProps = {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  labelPlacementStart?: boolean;
  adHocError?: string;
  name: string;
  hideError?: boolean;
  rules?: ComponentProps<typeof Controller>['rules'];
  render: (args: FormFieldRenderProps) => ReactElement;
};

/**
 * This component is a bridge between react-hook-form and MUI.
 * The render prop passed forward all the useController returned props and also
 * error and disabled props should be passed down to the MUI fields in order to show to correct styles, as borderColors
 */
export function FormField({
  fullWidth = true,
  required,
  label,
  adHocError,
  name,
  render,
  rules,
  hideError,
  labelPlacementStart,
}: FormFieldProps) {
  const t = useRootTranslation();
  const { field, fieldState, formState } = useController({
    name,
    rules,
  });

  const { error } = fieldState;
  const formValidationError = Boolean(formState.errors[name]);
  const hasError = !isEmpty(error) || formValidationError;
  const errorMessage = Array.isArray(error) ? error.find((e) => e?.message)?.message : error?.message;
  const helperText = t(errorMessage);

  return (
    <FormControlLabelWrapper
      fullWidth={fullWidth}
      label={label}
      name={name}
      required={required}
      helperText={helperText || adHocError}
      hideError={hideError}
      error={hasError || !!adHocError}
      labelPlacementStart={labelPlacementStart}
    >
      {render({
        field,
        fieldState,
        formState,
        muiprops: {
          error: hasError,
          id: name,
        },
      })}
    </FormControlLabelWrapper>
  );
}

export type FormControlLabelWrapperProps = Omit<FormFieldProps, 'render' | 'rules'> & {
  children: ReactNode;
  error?: boolean;
  hideError?: boolean;
  helperText?: string;
  disabled?: boolean;
};

type StyledFormControlProps = Pick<FormFieldProps, 'labelPlacementStart'>;

const StyledFormControl = styled(FormControl)<StyledFormControlProps>(({ labelPlacementStart }) => ({
  gap: '0.5rem',
  ...(labelPlacementStart && {
    '&.MuiFormControl-root': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
}));

export function FormControlLabelWrapper({
  fullWidth = true,
  required,
  label,
  name,
  helperText,
  error,
  disabled,
  children,
  hideError,
  ...rest
}: FormControlLabelWrapperProps): JSX.Element {
  return (
    <Box width="100%">
      <StyledFormControl
        fullWidth={fullWidth}
        error={error}
        {...rest}
      >
        {label && (
          <InputLabel
            htmlFor={name}
            required={required}
            disabled={disabled}
          >
            {label}
          </InputLabel>
        )}
        {children}
      </StyledFormControl>
      {error && !hideError && (
        <Fade in={error}>
          <Box sx={{ display: 'flex', alignItems: 'start', gap: '0.3rem', mt: '0.1rem' }}>
            <ErrorIcon color="error" fontSize="small" sx={{ mt: '0.1em' }} />
            <FormHelperText data-visible={error} error>
              {helperText}
            </FormHelperText>
          </Box>
        </Fade>
      )}
    </Box>
  );
}
